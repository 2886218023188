import React, { useContext, useState } from 'react'

const ShippingInfoContext = React.createContext();
const ShippingInfoUpdateContext = React.createContext();

export const useShippingInfoContext = () => {
    return useContext(ShippingInfoContext);
}

export const useShippingInfoUpdateContext = () => {
    return useContext(ShippingInfoUpdateContext);
}

export const ShippingInfoContextProvider = ({ children }) => {
    const [shippingInfo, setShippingInfo] = useState({});

    const resetShippingInfo = () => {
        setShippingInfo({});
    };

    // const clean = (obj) => {
    //     for (var prop in obj) { 
    //         if (obj[prop] === '') {
    //         delete obj[prop];
    //         }
    //     }
    // }

    const updateShippingInfo = (key, value) => {
        if(value) {
            setShippingInfo({
                ...shippingInfo,
                [key]: value
            });      
        } else if (value === "") {
            let si = {...shippingInfo}
            delete si[key]
            setShippingInfo(si)
        }
        
    }

    return(
        <ShippingInfoContext.Provider value={shippingInfo}>
            <ShippingInfoUpdateContext.Provider value={{updateShippingInfo, resetShippingInfo}}>
                {children}
            </ShippingInfoUpdateContext.Provider>
        </ShippingInfoContext.Provider>
    )
}
