import React, { useContext, useState } from 'react'

const OrderContext = React.createContext();
const OrderUpdateContext = React.createContext();

export const useOrderContext = () => {
    return useContext(OrderContext);
}

export const useOrderUpdateContext = () => {
    return useContext(OrderUpdateContext);
}

export const OrderContextProvider = ({ children }) => {
    const [order, setOrder] = useState([]);

    React.useEffect(() => {
        let localOrder = JSON.parse(localStorage.getItem("order"));
        alterOrder(localOrder)
    }, []);

    const alterOrder = (order) => {
        if(order) {
        setOrder(order)
        localStorage.setItem("order", JSON.stringify(order))
        }
    }
    
    return(
        <OrderContext.Provider value={order}>
            <OrderUpdateContext.Provider value={alterOrder}>
                {children}
            </OrderUpdateContext.Provider>
        </OrderContext.Provider>
    )
}
