import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import './App.css';

import ReactPixel from 'react-facebook-pixel';

import ScrollToTop from './functions/scrollToTop';

import { CartContextProvider } from './context/CartContext'
import { OrderContextProvider } from './context/OrderContext'
import { ShippingInfoContextProvider } from './context/ShippingInfoContext'

import Loader from './components/Loader.js';

import ReactGA from "react-ga4";
ReactGA.initialize("G-MGHKS0P9F0");
// const Loader = lazy(() => import('./components/Loader.js'));

const LandingView = lazy(() => import('./views/LandingView.js'));
const ProductView = lazy(() => import('./views/ProductView2.js'));
const CartView = lazy(() => import('./views/CartView.js'));
const OrderConfirmationView = lazy(() => import('./views/OrderConfirmationView.js'));

function App() {
  React.useEffect(() => {    
    ReactPixel.init('189765663324449');
    ReactPixel.pageView();
}, []);
  return (
    <CartContextProvider>
        <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        
          <div className="App">
          <Suspense fallback={<Loader/>}>
            <Route path='/' component={LandingView} exact></Route>
            <Route path='/product/:_id' render={(props) => <ProductView {...props} />} exact></Route>
            {/* <Route path='/about' component={AboutView} exact></Route> */}
            <ShippingInfoContextProvider>
              <OrderContextProvider>
                <Route path='/cart/:_id?' component={CartView} exact></Route>
                <Route path='/order-confirmation/' component={OrderConfirmationView} exact></Route>    
              </OrderContextProvider>
            </ShippingInfoContextProvider>      
          </Suspense>
          </div>
          
        </BrowserRouter>
    </CartContextProvider>
  );
}

export default App;
