import React from 'react'

import SyncLoader from "react-spinners/SyncLoader";


function Loader() {
    return (
        <div>
            <div className="spinner">
                <SyncLoader color="#ffa0d0" size="8vmin" margin="2vmin"/>
            </div>
            <p className="tac loadingTxt">loading...</p>
        </div>
    )
}

export default Loader
