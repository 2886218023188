import React, { useContext, useState } from 'react'

const CartContext = React.createContext();
const CartUpdateContext = React.createContext();

export const useCartContext = () => {
    return useContext(CartContext);
}

export const useCartUpdateContext = () => {
    return useContext(CartUpdateContext);
}

export const CartContextProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    React.useEffect(() => {
        let localCart = JSON.parse(localStorage.getItem("cart"));
        alterCart(localCart)
    }, []);

    const alterCart = (cart) => {
        if(cart) {
        setCart(cart)
        localStorage.setItem("cart", JSON.stringify(cart))
        }
    }
    
    return(
        <CartContext.Provider value={cart}>
            <CartUpdateContext.Provider value={alterCart}>
                {children}
            </CartUpdateContext.Provider>
        </CartContext.Provider>
    )
}
